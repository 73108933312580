
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DeviceDetail } from '../../types/devices'

@Component
export default class Base extends Vue {
  @Prop() readonly deviceId!: string;
  detail: DeviceDetail = {
    deviceId: '',
    deviceTypeName: '',
    deviceTypeId: '',
    deviceCode: '',
    deviceNumber: '',
    supplierId: '',
    deviceModelName: '',
    deviceStatus: '',
    runStatus: '',
    customerName: '',
    supplierName: '',
    agentName: '',
    isAllocated: ''
  }

  allocateStatus = {
    0: '未分配',
    1: '已分配客户',
    2: '已分配项目'
  }

  deviceStatus = {
    1: '正常',
    2: '故障'
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.selectDeviceByDeviceId, {
      deviceId: this.deviceId
    }).then(res => {
      this.detail = res
    })
  }
}
